import env from '@beam-australia/react-env'
import { DATADOG_SERVICE } from '@cms/utils/constants'
import {
  initializeAndStartRecordingDatadogRum,
  initializeDatadogBrowserLogs,
} from '@knauf-group/ct-shared-nextjs/datadog'

import { beforeSendBrowserLogs, beforeSendRum } from './beforeSend'

const datadogJsonConfig = JSON.parse(env('DATADOG_CONFIG') || '{}')

if (env('DATADOG_IS_RUM_ENABLED') === 'true') {
  initializeAndStartRecordingDatadogRum({
    service: DATADOG_SERVICE,
    version: `${env('APP_CMS_VERSION')}`,
    sessionSampleRate: datadogJsonConfig.sessionSampleRate,
    sessionReplaySampleRate: datadogJsonConfig.sessionReplaySampleRate,
    trackUserInteractions: datadogJsonConfig.trackUserInteractions,
    trackResources: datadogJsonConfig.trackResources,
    trackLongTasks: datadogJsonConfig.trackLongTasks,
    defaultPrivacyLevel: datadogJsonConfig.defaultPrivacyLevel,
    silentMultipleInit: datadogJsonConfig.silentMultipleInit,
    beforeSend: beforeSendRum,
  })
}

if (env('DATADOG_ARE_BROWSER_LOGS_ENABLED')) {
  initializeDatadogBrowserLogs({
    service: DATADOG_SERVICE,
    version: `${env('APP_CMS_VERSION')}`,
    // Use before send to filter out aborted requests logs to datadog
    beforeSend: beforeSendBrowserLogs,
  })
}
