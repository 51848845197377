const i18nextHttpBackend = require('i18next-http-backend')
const i18nConfig = require('./config/i18n')
const { DEFAULT_CROWDIN_LOCALE } = require('@knauf-group/ct-shared-nextjs/utils/constants')

const isBrowser = typeof window !== 'undefined'

/** @type {import('next-i18next').UserConfig} */
module.exports = {
  i18n: {
    defaultLocale: i18nConfig.defaultLocale,
    locales: i18nConfig.locales,
    localeDetection: false,
  },
  ns: ['cms', 'global-search', 'dam-assets', 'shared-nextjs'], // this is mandatory for crowdin OTA to work
  // en-US does not work with crowdin OTA so en-GB should be considered
  fallbackLng: {
    default: [DEFAULT_CROWDIN_LOCALE],
    en: ['en-GB'],
    de: ['de-DE'],
    'en-TX': ['en-GB'],
    'de-TX': ['de-DE'],
    'fr-TX': ['fr-BE'],
  },
  defaultNS: i18nConfig.defaultNS,
  serializeConfig: false,
  backend: {
    loadPath: (languages, namespaces) => {
      const lng = languages[0]
      const ns = namespaces[0]

      // used when fetching the latest version of the translations from Crowdin CDN
      const currentTimestamp = Date.now()

      return `https://distributions.crowdin.net/${process.env.CROWDIN_DISTRIBUTION_HASH}/content/main/${lng}/${ns}.json?timestamp=${currentTimestamp}`
    },
    reloadInterval: 1000 * 60 * 60 * 8, // 8h
  },
  use: isBrowser ? [] : [i18nextHttpBackend], // translations should not be fetched client side
}
